import '@aws-amplify/ui-react/styles.css';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';
import { getDisplayLangData } from '../../../component/json';
import { localStorageKeys } from '../../../constant/localStorage';

interface ExitModalProps {
  onCloseModal: () => void;
}
const ExitModal = ({ onCloseModal }: ExitModalProps) => {
  const Lang = getDisplayLangData();

  const closeModal = () => {
    localStorage.removeItem(localStorageKeys.exerciseJson);
    onCloseModal();
  };
  const exitExercise = () => {
    const episodeId = localStorage.getItem(localStorageKeys.episodeId);
    window.location.replace(`/stories/${episodeId}`);
  };

  return (
    <>
      <Modal isOpen={true} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent maxWidth="345px" textAlign="center">
          <ModalHeader fontSize="sm" bgColor="salmon" roundedTop={'md'}>
            {/* 確認 */}
            {Lang.KT_0099_01}
          </ModalHeader>
          <ModalBody>
            {/* 終了します。よろしいですか. */}
            {Lang.KT_0099_02}
          </ModalBody>
          <ModalFooter justifyContent="space-evenly">
            <Button className="btn-border" textColor="white" bgColor="gray" width="40%" onClick={closeModal}>
              {/* キャンセル */}
              {Lang.KT_0099_03}
            </Button>
            <Button className="btn-border" textColor="white" bgColor="orange" width="40%" onClick={exitExercise}>
              {/* OK */}
              {Lang.KT_0099_04}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ExitModal;
