// HACK 生成スクリプトを作成してこのファイルを作成するようにしたほうが安全
// HACK tsconfig.jsonのpathsを設定して@assetsのようなエイリアスを使いたいが、react-scriptsだとwebpackにパスを解決する設定が書かれていないので、react-app-rewiredかejectして設定を追加しないといけない react-app-rewired

/**
 * NOTE １箇所に集める理由
 * - 各ファイルでパスを記載してインポートしなくて済む
 * - ファイル名やディレクトリが変更されても、このファイルの変更のみで済む（変数名を変えた場合は呼び出し元も変更）
 * - 画像だけのテストコードが簡単になる
 */
import room_in_white from './images/room_in_white.png';
import navigate_white from './images/navigate_white.png';
import home_icon from './images/home/ホームアイコン_s.png';
import home_emma from './images/home/エマ_小.png';
import home_notification_window from './images/home/お知らせ窓_小.png';
import home_story_button from './images/home/ストーリーボタン_アイコン付_小.png';
import home_selfstudy_button from './images/home/自習ボタン_アイコン付_小.png';
import home_speech_bubble from './images/home/吹き出し_小.png';
import home_background from './images/home/背景_小.jpg';
import home_talk_button from './images/home/話すボタン_アイコン付 _小.png';
import bottom_story_mode from './images/bottom_story_mode.png';
import bottom_self_learning from './images/bottom_self_learning.png';
import bottom_japan_online2 from './images/bottom_japan_online2.png';
import circle_green from './images/circle_green.png';
import x from './images/x.png';
import arrow_right_white2 from './images/arrow_right_white2.png';
import circle_play_outline_white from './images/circle_play_outline_white.png';
import zoom from './images/zoom.png';
import sound_conversation_orange from './images/sound_conversation_orange.png';
import btn_pause from './images/btn_pause.png';
import btn_play from './images/btn_play.png';
import ski094_1_01 from './images/ski094-1-01.png';
import btn_01 from './images/btn_01.png';
import btn_02 from './images/btn_02.png';
import step1 from './images/step1.png';
import step1_icon from './images/step1_icon.png';
import step1_icon_selected from './images/step1_icon_selected.png';
import step2 from './images/step2.png';
import step2_icon from './images/step2_icon.png';
import step2_icon_selected from './images/step2_icon_selected.png';
import step3 from './images/step3.png';
import step3_icon from './images/step3_icon.png';
import step3_icon_selected from './images/step3_icon_selected.png';
import step4 from './images/step4.png';
import step4_icon_selected from './images/step4_icon_selected.png';
import kana_icon_s from './images/kana_icon_s.png';
import baseline_translate_black_48 from './images/baseline_translate_black_48.png';
import baseline_translate_black_lite from './images/baseline_translate_black_lite.png'
import tips_orange from './images/tips_orange.png';
import frames from './images/frames.png';
import book_orange from './images/book_orange.png';
import img_comment from './images/img_comment.png';
import heart_red from './images/heart_red.png';
import arrow_right_white from './images/arrow_right_white.png';
import arrow_left_white from './images/arrow_left_white.png';
import hutaba from './images/hutaba.png';
import btn_fav01 from './images/btn_fav01.png';
import btn_fav02 from './images/btn_fav02.png';
import Q_2x from './images/Q@2x.png';
import ico_menu01_2x from './images/ico_menu01@2x.png';
import bg_qa_img_2x from './images/bg_qa_img@2x.png';
import qa_main_bg02_2x from './images/qa_main_bg02@2x.png';
import sl_vocabulary from './images/sl_vocabulary.png';
import sl_grammar from './images/sl_grammar.png';
import sl_clock from './images/sl_clock.png';
import sl_calendar from './images/sl_calendar.png';
import sl_practice from './images/sl_practice.png';
import sl_achievement from './images/sl_achievement.png';
import self_learnling_white from './images/self_learnling_white.png';
import settings_white from './images/settings_white.png';
import arrow_right_grey from './images/arrow_right_grey.png';
import logo01_tate from './images/logo01_tate.png';
import cancel_white from './images/cancel_white.png';
import walk1 from './images/walk1.png';
import walk2 from './images/walk2.png';
import chapter_selected from './images/chapter_selected.png';
import menu_episode from './images/menu_episode.png';
import ico_copper_2x from './images/ico_copper@2x.png';
import ico_silver_2x from './images/ico_silver@2x.png';
import ico_gold_2x from './images/ico_gold@2x.png';
import achievement_emma from './images/achievement_emma.png';
import img_clock01 from './images/img_clock01.png';
import circle_play_orange from './images/circle_play_orange.png';
import bottom_notification from './images/bottom_notification.png';
import edit_grey from './images/edit_grey.png';
import arrow_right_black from './images/arrow_right_black.png';
import otukaresama from './images/otukaresama.png'
export const ASSETS_IMG = {
  walk1,
  walk2,
  chapter_selected,
  menu_episode,
  room_in_white,
  navigate_white,
  home_icon,
  home_emma,
  home_notification_window,
  home_story_button,
  home_selfstudy_button,
  home_talk_button,
  home_speech_bubble,
  home_background,
  bottom_story_mode,
  bottom_self_learning,
  bottom_japan_online2,
  circle_green,
  x,
  arrow_right_white2,
  circle_play_outline_white,
  zoom,
  sound_conversation_orange,
  btn_pause,
  btn_play,
  ski094_1_01,
  btn_01,
  btn_02,
  step1,
  step1_icon,
  step1_icon_selected,
  step2,
  step2_icon,
  step2_icon_selected,
  step3,
  step3_icon,
  step3_icon_selected,
  step4_icon_selected,
  step4,
  kana_icon_s,
  baseline_translate_black_48,
  baseline_translate_black_lite,
  tips_orange,
  frames,
  book_orange,
  img_comment,
  heart_red,
  arrow_right_white,
  arrow_left_white,
  hutaba,
  btn_fav01,
  btn_fav02,
  Q_2x,
  ico_menu01_2x,
  bg_qa_img_2x,
  qa_main_bg02_2x,
  sl_vocabulary,
  sl_grammar,
  sl_clock,
  sl_calendar,
  sl_practice,
  sl_achievement,
  self_learnling_white,
  settings_white,
  arrow_right_grey,
  logo01_tate,
  cancel_white,
  ico_copper_2x,
  ico_silver_2x,
  ico_gold_2x,
  achievement_emma,
  img_clock01,
  circle_play_orange,
  bottom_notification,
  edit_grey,
  arrow_right_black,
  otukaresama,
};
