import { useCallback, useEffect, useState } from 'react';
import { requestResoucesApi, resourcesApi } from '../../api/api';
import { isStoeyExercises } from '../../guard/type-guard/story-type-guard';
import { StoryExercises } from '../../_types/new-story';
import { StoryNo } from '../../_types/story';

export default function useStoryExercises(storyNo: StoryNo | '') {
  const [exercises, setExercises] = useState<StoryExercises>();
  const fetchExercises = useCallback(async () => {
    const responseEndpoint = await requestResoucesApi(() =>
      resourcesApi.resourcesControllerGetStoryExercisesEndpoint(storyNo),
    );
    if (!responseEndpoint?.data?.data) {
      return;
    }
    if (!Array.isArray(responseEndpoint?.data?.data)) {
      return;
    }
    const fetchRes = await fetch(responseEndpoint.data.data[0], { method: 'GET' });
    const exercises = await fetchRes.json();
    if (isStoeyExercises(exercises)) {
      setExercises(exercises);
    }
  }, [storyNo]);
  useEffect(() => {
    fetchExercises();
  }, []);
  return exercises;
}
