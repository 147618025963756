import { StoryId, StoryExercisesId } from '../_types/story';

// ストーリーID
export const storyId_1_1_1 = 'SEASON_1_EPISODE_1_SCENE_1';
export const storyId_1_1_2 = 'SEASON_1_EPISODE_1_SCENE_2';
export const storyId_1_1_3 = 'SEASON_1_EPISODE_1_SCENE_3';

export const storyId_1_2_1 = 'SEASON_1_EPISODE_2_SCENE_1';
export const storyId_1_2_2 = 'SEASON_1_EPISODE_2_SCENE_2';
export const storyId_1_2_3 = 'SEASON_1_EPISODE_2_SCENE_3';

export const storyId_1_3_1 = 'SEASON_1_EPISODE_3_SCENE_1';
export const storyId_1_3_2 = 'SEASON_1_EPISODE_3_SCENE_2';
export const storyId_1_3_3 = 'SEASON_1_EPISODE_3_SCENE_3';

export const storyId_1_4_1 = 'SEASON_1_EPISODE_4_SCENE_1';
export const storyId_1_4_2 = 'SEASON_1_EPISODE_4_SCENE_2';
export const storyId_1_4_3 = 'SEASON_1_EPISODE_4_SCENE_3';

export const storyId_1_5_1 = 'SEASON_1_EPISODE_5_SCENE_1';
export const storyId_1_5_2 = 'SEASON_1_EPISODE_5_SCENE_2';
export const storyId_1_5_3 = 'SEASON_1_EPISODE_5_SCENE_3';

export const storyId_1_6_1 = 'SEASON_1_EPISODE_6_SCENE_1';
export const storyId_1_6_2 = 'SEASON_1_EPISODE_6_SCENE_2';
export const storyId_1_6_3 = 'SEASON_1_EPISODE_6_SCENE_3';

export const storyId_1_7_1 = 'SEASON_1_EPISODE_7_SCENE_1';
export const storyId_1_7_2 = 'SEASON_1_EPISODE_7_SCENE_2';
export const storyId_1_7_3 = 'SEASON_1_EPISODE_7_SCENE_3';

export const storyId_1_8_1 = 'SEASON_1_EPISODE_8_SCENE_1';
export const storyId_1_8_2 = 'SEASON_1_EPISODE_8_SCENE_2';
export const storyId_1_8_3 = 'SEASON_1_EPISODE_8_SCENE_3';

export const storyId_1_9_1 = 'SEASON_1_EPISODE_9_SCENE_1';
export const storyId_1_9_2 = 'SEASON_1_EPISODE_9_SCENE_2';
export const storyId_1_9_3 = 'SEASON_1_EPISODE_9_SCENE_3';

export const storyId_1_10_1 = 'SEASON_1_EPISODE_10_SCENE_1';
export const storyId_1_10_2 = 'SEASON_1_EPISODE_10_SCENE_2';
export const storyId_1_10_3 = 'SEASON_1_EPISODE_10_SCENE_3';

export const storyId_1_11_1 = 'SEASON_1_EPISODE_11_SCENE_1';
export const storyId_1_11_2 = 'SEASON_1_EPISODE_11_SCENE_2';
export const storyId_1_11_3 = 'SEASON_1_EPISODE_11_SCENE_3';

export const storyId_1_12_1 = 'SEASON_1_EPISODE_12_SCENE_1';
export const storyId_1_12_2 = 'SEASON_1_EPISODE_12_SCENE_2';
export const storyId_1_12_3 = 'SEASON_1_EPISODE_12_SCENE_3';

export const storyId_1_13_1 = 'SEASON_1_EPISODE_13_SCENE_1';
export const storyId_1_13_2 = 'SEASON_1_EPISODE_13_SCENE_2';
export const storyId_1_13_3 = 'SEASON_1_EPISODE_13_SCENE_3';

export const storyId_1_14_1 = 'SEASON_1_EPISODE_14_SCENE_1';
export const storyId_1_14_2 = 'SEASON_1_EPISODE_14_SCENE_2';
export const storyId_1_14_3 = 'SEASON_1_EPISODE_14_SCENE_3';

export const storyId_1_15_1 = 'SEASON_1_EPISODE_15_SCENE_1';
export const storyId_1_15_2 = 'SEASON_1_EPISODE_15_SCENE_2';
export const storyId_1_15_3 = 'SEASON_1_EPISODE_15_SCENE_3';

export const storyId_1_16_1 = 'SEASON_1_EPISODE_16_SCENE_1';
export const storyId_1_16_2 = 'SEASON_1_EPISODE_16_SCENE_2';
export const storyId_1_16_3 = 'SEASON_1_EPISODE_16_SCENE_3';

export const storyIds = [
  storyId_1_1_1,
  storyId_1_1_2,
  storyId_1_1_3,
  storyId_1_2_1,
  storyId_1_2_2,
  storyId_1_2_3,
  storyId_1_3_1,
  storyId_1_3_2,
  storyId_1_3_3,
  storyId_1_4_1,
  storyId_1_4_2,
  storyId_1_4_3,
  storyId_1_5_1,
  storyId_1_5_2,
  storyId_1_5_3,
  storyId_1_6_1,
  storyId_1_6_2,
  storyId_1_6_3,
  storyId_1_7_1,
  storyId_1_7_2,
  storyId_1_7_3,
  storyId_1_8_1,
  storyId_1_8_2,
  storyId_1_8_3,
  storyId_1_9_1,
  storyId_1_9_2,
  storyId_1_9_3,
  storyId_1_10_1,
  storyId_1_10_2,
  storyId_1_10_3,
  storyId_1_11_1,
  storyId_1_11_2,
  storyId_1_11_3,
  storyId_1_12_1,
  storyId_1_12_2,
  storyId_1_12_3,
  storyId_1_13_1,
  storyId_1_13_2,
  storyId_1_13_3,
  storyId_1_14_1,
  storyId_1_14_2,
  storyId_1_14_3,
  storyId_1_15_1,
  storyId_1_15_2,
  storyId_1_15_3,
  storyId_1_16_1,
  storyId_1_16_2,
  storyId_1_16_3,
] as const;

// ストーリーNo x-x
export const storyNo_1_1 = '1-1';
export const storyNo_1_2 = '1-2';
export const storyNo_1_3 = '1-3';

export const storyNo_2_1 = '2-1';
export const storyNo_2_2 = '2-2';
export const storyNo_2_3 = '2-3';

export const storyNo_3_1 = '3-1';
export const storyNo_3_2 = '3-2';
export const storyNo_3_3 = '3-3';

export const storyNo_4_1 = '4-1';
export const storyNo_4_2 = '4-2';
export const storyNo_4_3 = '4-3';

export const storyNo_5_1 = '5-1';
export const storyNo_5_2 = '5-2';
export const storyNo_5_3 = '5-3';

export const storyNo_6_1 = '6-1';
export const storyNo_6_2 = '6-2';
export const storyNo_6_3 = '6-3';

export const storyNo_7_1 = '7-1';
export const storyNo_7_2 = '7-2';
export const storyNo_7_3 = '7-3';

export const storyNo_8_1 = '8-1';
export const storyNo_8_2 = '8-2';
export const storyNo_8_3 = '8-3';

export const storyNo_9_1 = '9-1';
export const storyNo_9_2 = '9-2';
export const storyNo_9_3 = '9-3';

export const storyNo_10_1 = '10-1';
export const storyNo_10_2 = '10-2';
export const storyNo_10_3 = '10-3';

export const storyNo_11_1 = '11-1';
export const storyNo_11_2 = '11-2';
export const storyNo_11_3 = '11-3';

export const storyNo_12_1 = '12-1';
export const storyNo_12_2 = '12-2';
export const storyNo_12_3 = '12-3';

export const storyNo_13_1 = '13-1';
export const storyNo_13_2 = '13-2';
export const storyNo_13_3 = '13-3';

export const storyNo_14_1 = '14-1';
export const storyNo_14_2 = '14-2';
export const storyNo_14_3 = '14-3';

export const storyNo_15_1 = '15-1';
export const storyNo_15_2 = '15-2';
export const storyNo_15_3 = '15-3';

export const storyNo_16_1 = '16-1';
export const storyNo_16_2 = '16-2';
export const storyNo_16_3 = '16-3';

export const storyNos = [
  storyNo_1_1,
  storyNo_1_2,
  storyNo_1_3,
  storyNo_2_1,
  storyNo_2_2,
  storyNo_2_3,
  storyNo_3_1,
  storyNo_3_2,
  storyNo_3_3,
  storyNo_4_1,
  storyNo_4_2,
  storyNo_4_3,
  storyNo_5_1,
  storyNo_5_2,
  storyNo_5_3,
  storyNo_6_1,
  storyNo_6_2,
  storyNo_6_3,
  storyNo_7_1,
  storyNo_7_2,
  storyNo_7_3,
  storyNo_8_1,
  storyNo_8_2,
  storyNo_8_3,
  storyNo_9_1,
  storyNo_9_2,
  storyNo_9_3,
  storyNo_10_1,
  storyNo_10_2,
  storyNo_10_3,
  storyNo_11_1,
  storyNo_11_2,
  storyNo_11_3,
  storyNo_12_1,
  storyNo_12_2,
  storyNo_12_3,
  storyNo_13_1,
  storyNo_13_2,
  storyNo_13_3,
  storyNo_14_1,
  storyNo_14_2,
  storyNo_14_3,
  storyNo_15_1,
  storyNo_15_2,
  storyNo_15_3,
  storyNo_16_1,
  storyNo_16_2,
  storyNo_16_3,
] as const;

// ストーリー練習問題
export const storyExercisesId_1_1_1 = 'SEASON_1_EPISODE_1_SCENE_1_EXERCISES';
export const storyExercisesId_1_1_2 = 'SEASON_1_EPISODE_1_SCENE_2_EXERCISES';
export const storyExercisesId_1_1_3 = 'SEASON_1_EPISODE_1_SCENE_3_EXERCISES';

export const storyExercisesId_1_2_1 = 'SEASON_1_EPISODE_2_SCENE_1_EXERCISES';
export const storyExercisesId_1_2_2 = 'SEASON_1_EPISODE_2_SCENE_2_EXERCISES';
export const storyExercisesId_1_2_3 = 'SEASON_1_EPISODE_2_SCENE_3_EXERCISES';

export const storyExercisesId_1_3_1 = 'SEASON_1_EPISODE_3_SCENE_1_EXERCISES';
export const storyExercisesId_1_3_2 = 'SEASON_1_EPISODE_3_SCENE_2_EXERCISES';
export const storyExercisesId_1_3_3 = 'SEASON_1_EPISODE_3_SCENE_3_EXERCISES';

export const storyExercisesId_1_4_1 = 'SEASON_1_EPISODE_4_SCENE_1_EXERCISES';
export const storyExercisesId_1_4_2 = 'SEASON_1_EPISODE_4_SCENE_2_EXERCISES';
export const storyExercisesId_1_4_3 = 'SEASON_1_EPISODE_4_SCENE_3_EXERCISES';

export const storyExercisesId_1_5_1 = 'SEASON_1_EPISODE_5_SCENE_1_EXERCISES';
export const storyExercisesId_1_5_2 = 'SEASON_1_EPISODE_5_SCENE_2_EXERCISES';
export const storyExercisesId_1_5_3 = 'SEASON_1_EPISODE_5_SCENE_3_EXERCISES';

export const storyExercisesId_1_6_1 = 'SEASON_1_EPISODE_6_SCENE_1_EXERCISES';
export const storyExercisesId_1_6_2 = 'SEASON_1_EPISODE_6_SCENE_2_EXERCISES';
export const storyExercisesId_1_6_3 = 'SEASON_1_EPISODE_6_SCENE_3_EXERCISES';

export const storyExercisesId_1_7_1 = 'SEASON_1_EPISODE_7_SCENE_1_EXERCISES';
export const storyExercisesId_1_7_2 = 'SEASON_1_EPISODE_7_SCENE_2_EXERCISES';
export const storyExercisesId_1_7_3 = 'SEASON_1_EPISODE_7_SCENE_3_EXERCISES';

export const storyExercisesId_1_8_1 = 'SEASON_1_EPISODE_8_SCENE_1_EXERCISES';
export const storyExercisesId_1_8_2 = 'SEASON_1_EPISODE_8_SCENE_2_EXERCISES';
export const storyExercisesId_1_8_3 = 'SEASON_1_EPISODE_8_SCENE_3_EXERCISES';

export const storyExercisesId_1_9_1 = 'SEASON_1_EPISODE_9_SCENE_1_EXERCISES';
export const storyExercisesId_1_9_2 = 'SEASON_1_EPISODE_9_SCENE_2_EXERCISES';
export const storyExercisesId_1_9_3 = 'SEASON_1_EPISODE_9_SCENE_3_EXERCISES';

export const storyExercisesId_1_10_1 = 'SEASON_1_EPISODE_10_SCENE_1_EXERCISES';
export const storyExercisesId_1_10_2 = 'SEASON_1_EPISODE_10_SCENE_2_EXERCISES';
export const storyExercisesId_1_10_3 = 'SEASON_1_EPISODE_10_SCENE_3_EXERCISES';

export const storyExercisesId_1_11_1 = 'SEASON_1_EPISODE_11_SCENE_1_EXERCISES';
export const storyExercisesId_1_11_2 = 'SEASON_1_EPISODE_11_SCENE_2_EXERCISES';
export const storyExercisesId_1_11_3 = 'SEASON_1_EPISODE_11_SCENE_3_EXERCISES';

export const storyExercisesId_1_12_1 = 'SEASON_1_EPISODE_12_SCENE_1_EXERCISES';
export const storyExercisesId_1_12_2 = 'SEASON_1_EPISODE_12_SCENE_2_EXERCISES';
export const storyExercisesId_1_12_3 = 'SEASON_1_EPISODE_12_SCENE_3_EXERCISES';

export const storyExercisesId_1_13_1 = 'SEASON_1_EPISODE_13_SCENE_1_EXERCISES';
export const storyExercisesId_1_13_2 = 'SEASON_1_EPISODE_13_SCENE_2_EXERCISES';
export const storyExercisesId_1_13_3 = 'SEASON_1_EPISODE_13_SCENE_3_EXERCISES';

export const storyExercisesId_1_14_1 = 'SEASON_1_EPISODE_14_SCENE_1_EXERCISES';
export const storyExercisesId_1_14_2 = 'SEASON_1_EPISODE_14_SCENE_2_EXERCISES';
export const storyExercisesId_1_14_3 = 'SEASON_1_EPISODE_14_SCENE_3_EXERCISES';

export const storyExercisesId_1_15_1 = 'SEASON_1_EPISODE_15_SCENE_1_EXERCISES';
export const storyExercisesId_1_15_2 = 'SEASON_1_EPISODE_15_SCENE_2_EXERCISES';
export const storyExercisesId_1_15_3 = 'SEASON_1_EPISODE_15_SCENE_3_EXERCISES';

export const storyExercisesId_1_16_1 = 'SEASON_1_EPISODE_16_SCENE_1_EXERCISES';
export const storyExercisesId_1_16_2 = 'SEASON_1_EPISODE_16_SCENE_2_EXERCISES';
export const storyExercisesId_1_16_3 = 'SEASON_1_EPISODE_16_SCENE_3_EXERCISES';

export const storyExercisesIds = [
  storyExercisesId_1_1_1,
  storyExercisesId_1_1_2,
  storyExercisesId_1_1_3,
  storyExercisesId_1_2_1,
  storyExercisesId_1_2_2,
  storyExercisesId_1_2_3,
  storyExercisesId_1_3_1,
  storyExercisesId_1_3_2,
  storyExercisesId_1_3_3,
  storyExercisesId_1_4_1,
  storyExercisesId_1_4_2,
  storyExercisesId_1_4_3,
  storyExercisesId_1_5_1,
  storyExercisesId_1_5_2,
  storyExercisesId_1_5_3,
  storyExercisesId_1_6_1,
  storyExercisesId_1_6_2,
  storyExercisesId_1_6_3,
  storyExercisesId_1_7_1,
  storyExercisesId_1_7_2,
  storyExercisesId_1_7_3,
  storyExercisesId_1_8_1,
  storyExercisesId_1_8_2,
  storyExercisesId_1_8_3,
  storyExercisesId_1_9_1,
  storyExercisesId_1_9_2,
  storyExercisesId_1_9_3,
  storyExercisesId_1_10_1,
  storyExercisesId_1_10_2,
  storyExercisesId_1_10_3,
  storyExercisesId_1_11_1,
  storyExercisesId_1_11_2,
  storyExercisesId_1_11_3,
  storyExercisesId_1_12_1,
  storyExercisesId_1_12_2,
  storyExercisesId_1_12_3,
  storyExercisesId_1_13_1,
  storyExercisesId_1_13_2,
  storyExercisesId_1_13_3,
  storyExercisesId_1_14_1,
  storyExercisesId_1_14_2,
  storyExercisesId_1_14_3,
  storyExercisesId_1_15_1,
  storyExercisesId_1_15_2,
  storyExercisesId_1_15_3,
  storyExercisesId_1_16_1,
  storyExercisesId_1_16_2,
  storyExercisesId_1_16_3,
] as const;

export const storyExercisesIdFromStpryId: {
  [key in StoryId]: StoryExercisesId;
} = {
  SEASON_1_EPISODE_1_SCENE_1: 'SEASON_1_EPISODE_1_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_1_SCENE_2: 'SEASON_1_EPISODE_1_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_1_SCENE_3: 'SEASON_1_EPISODE_1_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_2_SCENE_1: 'SEASON_1_EPISODE_2_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_2_SCENE_2: 'SEASON_1_EPISODE_2_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_2_SCENE_3: 'SEASON_1_EPISODE_2_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_3_SCENE_1: 'SEASON_1_EPISODE_3_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_3_SCENE_2: 'SEASON_1_EPISODE_3_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_3_SCENE_3: 'SEASON_1_EPISODE_3_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_4_SCENE_1: 'SEASON_1_EPISODE_4_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_4_SCENE_2: 'SEASON_1_EPISODE_4_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_4_SCENE_3: 'SEASON_1_EPISODE_4_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_5_SCENE_1: 'SEASON_1_EPISODE_5_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_5_SCENE_2: 'SEASON_1_EPISODE_5_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_5_SCENE_3: 'SEASON_1_EPISODE_5_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_6_SCENE_1: 'SEASON_1_EPISODE_6_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_6_SCENE_2: 'SEASON_1_EPISODE_6_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_6_SCENE_3: 'SEASON_1_EPISODE_6_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_7_SCENE_1: 'SEASON_1_EPISODE_7_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_7_SCENE_2: 'SEASON_1_EPISODE_7_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_7_SCENE_3: 'SEASON_1_EPISODE_7_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_8_SCENE_1: 'SEASON_1_EPISODE_8_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_8_SCENE_2: 'SEASON_1_EPISODE_8_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_8_SCENE_3: 'SEASON_1_EPISODE_8_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_9_SCENE_1: 'SEASON_1_EPISODE_9_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_9_SCENE_2: 'SEASON_1_EPISODE_9_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_9_SCENE_3: 'SEASON_1_EPISODE_9_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_10_SCENE_1: 'SEASON_1_EPISODE_10_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_10_SCENE_2: 'SEASON_1_EPISODE_10_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_10_SCENE_3: 'SEASON_1_EPISODE_10_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_11_SCENE_1: 'SEASON_1_EPISODE_11_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_11_SCENE_2: 'SEASON_1_EPISODE_11_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_11_SCENE_3: 'SEASON_1_EPISODE_11_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_12_SCENE_1: 'SEASON_1_EPISODE_12_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_12_SCENE_2: 'SEASON_1_EPISODE_12_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_12_SCENE_3: 'SEASON_1_EPISODE_12_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_13_SCENE_1: 'SEASON_1_EPISODE_13_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_13_SCENE_2: 'SEASON_1_EPISODE_13_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_13_SCENE_3: 'SEASON_1_EPISODE_13_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_14_SCENE_1: 'SEASON_1_EPISODE_14_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_14_SCENE_2: 'SEASON_1_EPISODE_14_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_14_SCENE_3: 'SEASON_1_EPISODE_14_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_15_SCENE_1: 'SEASON_1_EPISODE_15_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_15_SCENE_2: 'SEASON_1_EPISODE_15_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_15_SCENE_3: 'SEASON_1_EPISODE_15_SCENE_3_EXERCISES',
  SEASON_1_EPISODE_16_SCENE_1: 'SEASON_1_EPISODE_16_SCENE_1_EXERCISES',
  SEASON_1_EPISODE_16_SCENE_2: 'SEASON_1_EPISODE_16_SCENE_2_EXERCISES',
  SEASON_1_EPISODE_16_SCENE_3: 'SEASON_1_EPISODE_16_SCENE_3_EXERCISES',
} as const;

// IDマッピング
export const storyNoFromStoryId = {
  [storyId_1_1_1]: storyNo_1_1,
  [storyId_1_1_2]: storyNo_1_2,
  [storyId_1_1_3]: storyNo_1_3,
  [storyId_1_2_1]: storyNo_2_1,
  [storyId_1_2_2]: storyNo_2_2,
  [storyId_1_2_3]: storyNo_2_3,
  [storyId_1_3_1]: storyNo_3_1,
  [storyId_1_3_2]: storyNo_3_2,
  [storyId_1_3_3]: storyNo_3_3,
  [storyId_1_4_1]: storyNo_4_1,
  [storyId_1_4_2]: storyNo_4_2,
  [storyId_1_4_3]: storyNo_4_3,
  [storyId_1_5_1]: storyNo_5_1,
  [storyId_1_5_2]: storyNo_5_2,
  [storyId_1_5_3]: storyNo_5_3,
  [storyId_1_6_1]: storyNo_6_1,
  [storyId_1_6_2]: storyNo_6_2,
  [storyId_1_6_3]: storyNo_6_3,
  [storyId_1_7_1]: storyNo_7_1,
  [storyId_1_7_2]: storyNo_7_2,
  [storyId_1_7_3]: storyNo_7_3,
  [storyId_1_8_1]: storyNo_8_1,
  [storyId_1_8_2]: storyNo_8_2,
  [storyId_1_8_3]: storyNo_8_3,
  [storyId_1_9_1]: storyNo_9_1,
  [storyId_1_9_2]: storyNo_9_2,
  [storyId_1_9_3]: storyNo_9_3,
  [storyId_1_10_1]: storyNo_10_1,
  [storyId_1_10_2]: storyNo_10_2,
  [storyId_1_10_3]: storyNo_10_3,
  [storyId_1_11_1]: storyNo_11_1,
  [storyId_1_11_2]: storyNo_11_2,
  [storyId_1_11_3]: storyNo_11_3,
  [storyId_1_12_1]: storyNo_12_1,
  [storyId_1_12_2]: storyNo_12_2,
  [storyId_1_12_3]: storyNo_12_3,
  [storyId_1_13_1]: storyNo_13_1,
  [storyId_1_13_2]: storyNo_13_2,
  [storyId_1_13_3]: storyNo_13_3,
  [storyId_1_14_1]: storyNo_14_1,
  [storyId_1_14_2]: storyNo_14_2,
  [storyId_1_14_3]: storyNo_14_3,
  [storyId_1_15_1]: storyNo_15_1,
  [storyId_1_15_2]: storyNo_15_2,
  [storyId_1_15_3]: storyNo_15_3,
  [storyId_1_16_1]: storyNo_16_1,
  [storyId_1_16_2]: storyNo_16_2,
  [storyId_1_16_3]: storyNo_16_3,
} as const;

export const storyIdFromStoryNo = {
  [storyNo_1_1]: storyId_1_1_1,
  [storyNo_1_2]: storyId_1_1_2,
  [storyNo_1_3]: storyId_1_1_3,
  [storyNo_2_1]: storyId_1_2_1,
  [storyNo_2_2]: storyId_1_2_2,
  [storyNo_2_3]: storyId_1_2_3,
  [storyNo_3_1]: storyId_1_3_1,
  [storyNo_3_2]: storyId_1_3_2,
  [storyNo_3_3]: storyId_1_3_3,
  [storyNo_4_1]: storyId_1_4_1,
  [storyNo_4_2]: storyId_1_4_2,
  [storyNo_4_3]: storyId_1_4_3,
  [storyNo_5_1]: storyId_1_5_1,
  [storyNo_5_2]: storyId_1_5_2,
  [storyNo_5_3]: storyId_1_5_3,
  [storyNo_6_1]: storyId_1_6_1,
  [storyNo_6_2]: storyId_1_6_2,
  [storyNo_6_3]: storyId_1_6_3,
  [storyNo_7_1]: storyId_1_7_1,
  [storyNo_7_2]: storyId_1_7_2,
  [storyNo_7_3]: storyId_1_7_3,
  [storyNo_8_1]: storyId_1_8_1,
  [storyNo_8_2]: storyId_1_8_2,
  [storyNo_8_3]: storyId_1_8_3,
  [storyNo_9_1]: storyId_1_9_1,
  [storyNo_9_2]: storyId_1_9_2,
  [storyNo_9_3]: storyId_1_9_3,
  [storyNo_10_1]: storyId_1_10_1,
  [storyNo_10_2]: storyId_1_10_2,
  [storyNo_10_3]: storyId_1_10_3,
  [storyNo_11_1]: storyId_1_11_1,
  [storyNo_11_2]: storyId_1_11_2,
  [storyNo_11_3]: storyId_1_11_3,
  [storyNo_12_1]: storyId_1_12_1,
  [storyNo_12_2]: storyId_1_12_2,
  [storyNo_12_3]: storyId_1_12_3,
  [storyNo_13_1]: storyId_1_13_1,
  [storyNo_13_2]: storyId_1_13_2,
  [storyNo_13_3]: storyId_1_13_3,
  [storyNo_14_1]: storyId_1_14_1,
  [storyNo_14_2]: storyId_1_14_2,
  [storyNo_14_3]: storyId_1_14_3,
  [storyNo_15_1]: storyId_1_15_1,
  [storyNo_15_2]: storyId_1_15_2,
  [storyNo_15_3]: storyId_1_15_3,
  [storyNo_16_1]: storyId_1_16_1,
  [storyNo_16_2]: storyId_1_16_2,
  [storyNo_16_3]: storyId_1_16_3,
} as const;
