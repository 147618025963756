import { Box, Text, VStack, Button, Flex, Image, HStack, Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { KanaOption, LangOption } from '../../../index';
import { BaseQuestion } from '../../../../../_types/new-story';
import ResultModal from '../../../Modal/ResultModal';
import { ASSETS_IMG } from '../../../../../assets/assets';
import { getDisplayLangData } from '../../../../../component/json';
import { Answer } from '../../../../../interfaces/exerciseInterface';
import './SortQuestion.scss';
interface SortQuestionProps {
  baseQuestion: BaseQuestion;
  langOption: LangOption;
  kanaOption: KanaOption;
  answerOrder: string;
  setAnswerOrder: React.Dispatch<React.SetStateAction<string>>;
  shuffledAnswers: any;
  handleNextQuestion: (answer: Answer) => void;
}

const SortQuestion: React.FC<SortQuestionProps> = ({
  baseQuestion,
  langOption,
  kanaOption,
  answerOrder,
  setAnswerOrder,
  shuffledAnswers,
  handleNextQuestion,
}) => {
  const { question, answers, indicative } = baseQuestion;
  const Lang = getDisplayLangData();
  const circle_play_outline_white = ASSETS_IMG.circle_play_outline_white;
  const [selectedModal, setSelectedModal] = useState<string>('');
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const colorCodeArray = ['#ef7891', '#94b73d', '#4fa8a1', '#b67de2'];

  useEffect(() => {
    prepareAnswer();
  }, [answerOrder]);

  const prepareAnswer = () => {
    if (answerOrder.length === answers.length) {
      let newSelectedAnswer = '';
      for (let i = 0; i < answerOrder.length; i++) {
        const serialIndex = Number(answerOrder[i]);
        newSelectedAnswer += shuffledAnswers[serialIndex][langOption];
      }
      setSelectedAnswer(newSelectedAnswer);
    } else {
      setSelectedAnswer('');
    }
  };

  const updateAnswerOrder = (order: number) => {
    const orderString = order.toString();
    if (answerOrder.includes(orderString)) {
      setAnswerOrder(answerOrder.replace(orderString, ''));
    } else {
      if (!answerOrder.includes(orderString)) {
        setAnswerOrder(answerOrder + orderString);
      }
    }
  };

  const onSelectAnswer = () => {
    setSelectedModal('ResultModal');
  };

  const onCloseModal = () => {
    setSelectedModal('');
  };

  const Answers = () => {
    return (
      <>
        {shuffledAnswers
          ? shuffledAnswers.map((answer: any, index: number) => {
              let target = answerOrder.indexOf(index.toString());
              target = target !== -1 ? target : 0;
              const isSelected = answerOrder.includes(index.toString());

              return (
                <Button
                  key={index}
                  className="custom-button"
                  w="80%"
                  minHeight={{ base: '55px', md: '50px', lg: '60px' }}
                  borderColor={isSelected ? colorCodeArray[target] : '#bdbdbd'}
                  borderRadius="15px"
                  borderWidth={isSelected ? '3px 3.5px 7px 4px' : '1px'}
                  boxShadow={isSelected ? 'none' : '0 3px #bdbdbd'}
                  onClick={() => updateAnswerOrder(index)}
                >
                  <Box className="circle" bg={colorCodeArray[target]} hidden={!isSelected}>
                    <span className="number-circle">{target + 1}</span>
                  </Box>
                  <Text fontSize="15px">{answer[langOption]}</Text>
                </Button>
              );
            })
          : null}
      </>
    );
  };

  const PreparedAnswers = () => {
    const sentence = question[kanaOption];

    let replacements = Array.from({ length: answers.length }, (_, index) =>
      answerOrder[index] !== undefined ? shuffledAnswers[Number(answerOrder[index])][langOption] || undefined : '',
    );

    const parts = sentence.split(/＿＿|＿/);

    return (
      <HStack>
        <Box>
          {parts.map((part, index) => (
            <span key={index}>
              {part}
              {index < replacements.length && replacements[index] ? (
                <span style={{ textDecoration: 'underline' }}>{replacements[index]}</span>
              ) : (
                index < replacements.length && '＿'
              )}
            </span>
          ))}
        </Box>
      </HStack>
    );
  };
  const Contents = () => {
    return (
      <>
        {selectedModal === 'ResultModal' && (
          <ResultModal
            baseQuestion={baseQuestion}
            selectedAnswer={selectedAnswer}
            kanaOption={kanaOption}
            onCloseModal={onCloseModal}
            handleNextQuestion={handleNextQuestion}
          />
        )}
        <VStack width="100%" display={'flex'} alignItems="center" justifyContent={'center'} m={1} gap={1}>
          <Box fontSize={{ base: '16px', md: '20px', lg: '24px' }} ml={10} mr={10}>
            <Text mb={2}>{indicative[langOption]}</Text>
            <HStack gap={1}>
              {shuffledAnswers
                ? shuffledAnswers.map((_: any, index: number) => (
                    <VStack key={index}>
                      <Box
                        bg={colorCodeArray[index]}
                        color="white"
                        borderRadius="50%"
                        width="25px"
                        height="25px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text fontSize="md">{index + 1}</Text>
                      </Box>
                      <Box width={'35px'} height={'2px'} bg="#e0e0e1"></Box>
                    </VStack>
                  ))
                : null}
              <Box fontWeight={'bold'}>。</Box>
            </HStack>
            <PreparedAnswers />
          </Box>
        </VStack>
        <VStack width="100%" display={'flex'} alignItems="center" justifyContent={'center'} m={1} spacing={3}>
          <Answers />
          <Flex justifyContent="center">
            <Button
              w="200px"
              h="60px"
              my={'20px'}
              justifyContent="center"
              color="white"
              fontSize="sm"
              bgColor={selectedAnswer === '' ? '#a0a0a0' : 'orange'}
              border={'1px solid black'}
              disabled={selectedAnswer === ''}
              _hover={{
                bgColor: selectedAnswer === '' ? '#a0a0a0' : 'orange',
              }}
              _disabled={{
                bgColor: '#a0a0a0',
                cursor: 'not-allowed',
              }}
              boxShadow={'0 3px black'}
              onClick={() => onSelectAnswer()}
            >
              <Image mr="5px" src={circle_play_outline_white} sizes="md" h="20px"></Image>
              <Text fontSize={'15px'}>{Lang.KT_0023_02}</Text>
            </Button>
          </Flex>
        </VStack>
      </>
    );
  };

  return <>{shuffledAnswers ? <Contents /> : <Spinner />}</>;
};

export default SortQuestion;
