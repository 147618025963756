import { Stack, Text, HStack, Image, Button, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { getDisplayLangData } from '../../../../component/json';
import StoryHeader from '../../Component/Header/StoryHeader';
import { ASSETS_IMG } from '../../../../assets/assets';
import { RxCrossCircled } from 'react-icons/rx';
import { localStorageKeys } from '../../../../constant/localStorage';
import './index.scss';

type Props = {};

const EpisodeCompletion: FC<Props> = ({}) => {
  const Lang = getDisplayLangData();
  const circle_play_outline_white = ASSETS_IMG.circle_play_outline_white;
  const image = ASSETS_IMG.otukaresama;
  const episodeId = localStorage.getItem(localStorageKeys.episodeId);

  const onNextScene = async () => {
    const episodeId = Number(localStorage.getItem(localStorageKeys.episodeId));
    const sceneId = Number(localStorage.getItem(localStorageKeys.sceneId));
    const seasonId = Number(localStorage.getItem(localStorageKeys.seasonId));
    const seasonSummary = Boolean(localStorage.getItem(localStorageKeys.seasonSummary) == 'true');
    const episodeSummary = Boolean(localStorage.getItem(localStorageKeys.episodeSummary) == 'true');

    if (seasonSummary == false && episodeSummary == false) {
      // シーズンまとめ以外且つ次がエピソードが変わらない場合
      localStorage.setItem(localStorageKeys.sceneId, String(sceneId + 1));
      localStorage.setItem(localStorageKeys.isNextEpisode, 'false');
      localStorage.setItem(localStorageKeys.isNextScene, 'true');
    } else if (seasonSummary == true) {
      // シーズンまとめの場合
      if (seasonId == 0) {
        localStorage.setItem(localStorageKeys.seasonId, String(seasonId + 1));
        localStorage.setItem(localStorageKeys.episodeId, '0');
        localStorage.setItem(localStorageKeys.isNextSummary, 'false');
        localStorage.setItem(localStorageKeys.isNextEpisode, 'true');
        localStorage.setItem(localStorageKeys.isNextScene, 'true');
        localStorage.setItem(localStorageKeys.sceneId, '0');

        localStorage.setItem(localStorageKeys.isNextEpisode, 'true');
        localStorage.setItem(localStorageKeys.isNextScene, 'true');
      }
    } else {
      // シーズンまとめ以外且つ次がエピソードが変わる場合
      if (episodeId == 15) {
        // 次がシーズンまとめに変わる場合
        localStorage.setItem(localStorageKeys.isNextSummary, 'true');
        localStorage.setItem(localStorageKeys.isNextEpisode, 'false');
        localStorage.setItem(localStorageKeys.isNextScene, 'true');
        localStorage.setItem(localStorageKeys.sceneId, '0');
      } else {
        // 次のエピソードになる場合（シーズン内）
        localStorage.setItem(localStorageKeys.episodeId, String(episodeId + 1));
        localStorage.setItem(localStorageKeys.isNextEpisode, 'true');
        localStorage.setItem(localStorageKeys.isNextScene, 'true');
        localStorage.setItem(localStorageKeys.sceneId, '0');
      }
    }
    window.location.replace(`/stories/${episodeId}`);
  };

  return (
    <>
      <Stack
        className="custom-stack"
        bgSize="cover"
        boxSizing="content-box"
        bgColor="cornsilk"
        borderRadius="5px 5px 0 0"
        m={'auto'}
        width={'100%'}
        height={'100vh'}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: 'auto',
        }}
      >
        <StoryHeader />
        <VStack height="100%" p={'5'} justifyContent="space-between">
          <VStack bg={'white'} p="5">
            <Image src={image} width={{ base: '550px', lg: '600px' }} p={1}></Image>
            <Text fontWeight={'medium'}>{Lang.go_on_next}</Text>
          </VStack>
          <HStack justifyContent={'space-between'} width="100%" p={1}>
            <Button
              h="60px"
              justifyContent="center"
              color="white"
              fontSize="sm"
              bgColor="#0699fb"
              border={'1px solid black'}
              boxShadow={'0 3px black'}
              onClick={() => window.location.replace(`/stories/${episodeId}`)}
              leftIcon={<RxCrossCircled color="white" size="25px" strokeWidth={'0.2'} />}
            >
              <Text fontSize={'15px'}>{Lang.KT_0024_07}</Text>
            </Button>
            <Button
              w="150px"
              h="60px"
              justifyContent="center"
              color="white"
              fontSize="sm"
              bgColor="orange"
              onClick={() => onNextScene()}
              border={'1px solid black'}
              boxShadow={'0 3px black'}
            >
              <Image mr="5px" src={circle_play_outline_white} sizes="md" h="20px"></Image>
              <Text fontSize={'15px'}>{Lang.KT_0024_05}</Text>
            </Button>
          </HStack>
        </VStack>
      </Stack>
    </>
  );
};
export default EpisodeCompletion;
