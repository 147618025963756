import { Box, HStack, Image } from "@chakra-ui/react";
import React from "react";
import { KanaOption, LangOption } from "../..";
import { ASSETS_IMG } from "../../../../assets/assets";
import { localStorageKeys } from "../../../../constant/localStorage";

interface HeaderButtonsProps {
  currentQuestion: number;
  langOption: LangOption;
  setLangOption: (option: LangOption) => void;
  kanaOption: KanaOption;
  setKanaOption: (option: KanaOption) => void;
}
const HeaderButtons: React.FC<HeaderButtonsProps> = ({ 
  currentQuestion, 
  langOption, 
  setLangOption, 
  kanaOption, 
  setKanaOption 
}) => {
  const kana_icon_s = ASSETS_IMG.kana_icon_s;
  const baseline_translate_black_lite = ASSETS_IMG.baseline_translate_black_lite;
  const systemLanguage = String(localStorage.getItem(localStorageKeys.systemLanguage));

  const handleKanaOptionChange = () => {
    if(kanaOption === 'ja') {
      setKanaOption('kana');
    } else {
      setKanaOption('ja');
    }
  }

  const handleLangOptionChange = () => {
    if(systemLanguage === 'ja') {
      if(langOption === 'ja') {
        setLangOption('en');
      } else {
        setLangOption('ja');
      }
    } else {
      if(langOption === 'ja') {
        setLangOption(systemLanguage as LangOption);
      } else {
        setLangOption('ja');
      }
    }
  }
  
  return (
    <HStack width="100%" display={'flex'} alignItems="center" justifyContent={'center'} m={1}>
        <Box width={{ base: '60%', sm: '70%', md: '80%' }}>
          <Box
              border="2px"
              width={{ base: "30px", md: "35px", lg: "45px" }}
              height={{ base: "30px", md: "35px", lg: "45px" }}
              borderColor="black"
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={{ base: 1, md: 2, lg: 3 }}
              fontSize={{ base: "12px", md: "16px", lg: "20px" }}
          >
              {`Q${currentQuestion}`}
          </Box>
        </Box>
        <Box
          border="2px"
          width={{ base: "30px", md: "35px", lg: "45px" }}
          height={{ base: "30px", md: "35px", lg: "45px" }}
          background={kanaOption === 'kana' ? "#afd31a" : "#fff"}
          borderRadius="md"
          borderColor={'#bdc0b1'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={handleKanaOptionChange}
          _hover={{ 
            background: "#fff",
            borderColor: "#afd31a",
            cursor: "pointer"
          }}
        >
          <Image w="30px" h="30px" src={kana_icon_s} />
        </Box>
        <Box
          border="2px"
          width={{ base: "30px", md: "35px", lg: "45px" }}
          height={{ base: "30px", md: "35px", lg: "45px" }}
          background={langOption === systemLanguage ? "#afd31a" : "#fff"}
          borderRadius="md"
          borderColor={'#bdc0b1'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={handleLangOptionChange}
          _hover={{ 
            background: "#fff",
            borderColor: "#afd31a",
            cursor: "pointer"
          }}
        >
          <Image w="30px" h="30px" src={baseline_translate_black_lite} />
        </Box>
    </HStack>
  );
}

export default HeaderButtons;