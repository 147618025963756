const lsKeyObject = {
  systemLanguage: '',
  episodeId: '',
  translationLanguage: '',
  displayJPMode: '',
  wordLikes: '',
  expressions: '',
  translationFlg: '',
  sectionData: '',
  currentPage: '',
  sceneId: '',
  seasonId: '',
  sceneTitle: '',
  sectionTitle: '',
  seasonSummary: '',
  episodeSummary: '',
  storyTitle: '',
  storyLabel: '',
  isNextScene: '',
  isNextEpisode: '',
  isNextSummary: '',
  bgEpisodeFile: '',
  agreed: '',
  answerSelect: '',
  answerSelectText: '',
  questionText: '',
  answer: '',
  answerText: '',
  questionNo: '',
  totalQuestionNum: '',
  questionType: '',
  answerType: '',
  questionTitle: '',
  questionAudio: '',
  correctSelect: '',
  questionImage_url: '',
  countSubQuestions: '',
  subQuestionCount: '',
  correctText: '',
  isEnableSubQuestion: '',
  questionVerb: '',
  question: '',
  AnswerWords: '',
  answerWordNum: '',
  Title: '',
  AnswerLock: '',
  AnswerWordNum: '',
  Verb: '',
  Audio: '',
  CorrectText: '',
  questionNum: '',
  Prev: '',
  Next: '',
  grammarLikes: '',
  dlillsName: '',
  dlillsId: '',
  answerWords: '',
  correctWordSelect: '',
  correctWordPrev: '',
  correctWordNext: '',
  storiesData: '',
  QAData: '',
  subQuestionNo:'',
  exerciseJson:'',
} as const;

type LsKey = keyof typeof lsKeyObject;
const keys = Object.keys(lsKeyObject) as LsKey[];

export const localStorageKeys = keys.reduce((obj, key) => ({ ...obj, [key]: key }), {} as { [key in LsKey]: string });
