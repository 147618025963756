export enum Answer {
  CORRECT = 1,
  INCORRECT = 0,
}

export interface Answers {
  [key: number]: Answer;
}

export interface Exercise {
  currentQuestion: number;
  answers: Answers;
  correctCount: number;
}
