import { Box, Stack, Text, VStack, Button, Flex, Image } from '@chakra-ui/react';
import { FC, useState, useEffect } from 'react';
import { KanaOption, LangOption } from '../../../index';
import { BaseQuestion } from '../../../../../_types/new-story';
import ResultModal from '../../../Modal/ResultModal';
import { ASSETS_IMG } from '../../../../../assets/assets';
import { getDisplayLangData } from '../../../../../component/json';
import { Answer } from '../../../../../interfaces/exerciseInterface';
interface FourChoiceQuestionProps {
  baseQuestion: BaseQuestion;
  langOption: LangOption;
  kanaOption: KanaOption;
  selectedAnswer: string;
  setSelectedAnswer: React.Dispatch<React.SetStateAction<string>>;
  handleNextQuestion: (answer: Answer) => void;
}

const FourChoiceQuestion: React.FC<FourChoiceQuestionProps> = ({
  baseQuestion,
  langOption,
  kanaOption,
  selectedAnswer,
  setSelectedAnswer,
  handleNextQuestion,
}) => {
  const { question, answers, indicative } = baseQuestion;
  const Lang = getDisplayLangData();
  const circle_play_outline_white = ASSETS_IMG.circle_play_outline_white;
  const [selectedModal, setSelectedModal] = useState<string>('');

  const onCloseModal = () => {
    setSelectedModal('');
  };

  const onSelectAnswer = (answer: any) => {
    setSelectedAnswer(answer[langOption]);
  };

  return (
    <>
      {selectedModal === 'ResultModal' && (
        <ResultModal
          baseQuestion={baseQuestion}
          selectedAnswer={selectedAnswer}
          kanaOption={kanaOption}
          onCloseModal={onCloseModal}
          handleNextQuestion={handleNextQuestion}
        />
      )}
      <Stack width="100%" display={'flex'} alignItems="center" justifyContent={'center'} m={1}>
        <Box fontSize={{ base: '16px', md: '20px', lg: '24px' }} ml={10} mr={10}>
          <Text mb={2}>
            {langOption === 'ja' 
              ? indicative[kanaOption]
              : indicative[langOption]
            }
          </Text>
          {question[kanaOption].split('\n').map((line, index) => (
            <Box key={index} fontWeight={'bold'}>
              {line}
            </Box>
          ))}
        </Box>
      </Stack>
      <VStack width="100%" display={'flex'} alignItems="center" justifyContent={'center'} m={1} spacing={3}>
        {/* answer */}
        {answers.map((answer, index) => (
          <Box
            key={index}
            cursor={'pointer'}
            width={'80%'}
            minHeight={{ base: '55px', md: '50px', lg: '60px' }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            background={selectedAnswer === answer[langOption] ? 'orange' : '#e2e2e2'}
            borderColor={'#bdc0b1'}
            borderRadius={'15px'}
            boxShadow={selectedAnswer === answer[langOption] ? 'none' : '0 3px #bdbdbd'}
            onClick={() => onSelectAnswer(answer)}
          >
            <Text fontWeight={'medium'} fontSize={{ base: '14px', md: '15px', lg: '20px' }}>
              {answer[langOption]}
            </Text>
          </Box>
        ))}
        <Flex justifyContent="center">
          <Button
            w="200px"
            h="60px"
            my={'20px'}
            justifyContent="center"
            color="white"
            fontSize="sm"
            bgColor={selectedAnswer === '' ? '#a0a0a0' : 'orange'}
            border={'1px solid black'}
            disabled={selectedAnswer === ''}
            _hover={{
              bgColor: selectedAnswer === '' ? '#a0a0a0' : 'orange',
            }}
            _disabled={{
              bgColor: '#a0a0a0',
              cursor: 'not-allowed',
            }}
            boxShadow={'0 3px black'}
            onClick={() => setSelectedModal('ResultModal')}
          >
            <Image mr="5px" src={circle_play_outline_white} sizes="md" h="20px"></Image>
            <Text fontSize={'15px'}>{Lang.KT_0023_02}</Text>
          </Button>
        </Flex>
      </VStack>
    </>
  );
};

export default FourChoiceQuestion;
