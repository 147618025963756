import { Box, Flex, HStack, Spinner, Stack, Text } from '@chakra-ui/react';
import { BsXCircleFill } from 'react-icons/bs';
import { localStorageKeys } from '../../../../constant/localStorage';
import { FC } from 'react';
import { useState } from 'react';
import ExitModal from '../../Modal/ExitModal';

type Props = {};
const StoryHeader: FC<Props> = () => {
  const StoryLabel = String(localStorage.getItem(localStorageKeys.storyLabel));
  const StoryTitle = String(localStorage.getItem(localStorageKeys.storyTitle));
  const [selectedModal, setSelectedModal] = useState<string>('');

  const onOpenModal = (name: string) => {
    setSelectedModal(name);
  };

  const onCloseModal = () => {
    setSelectedModal('');
  };
  return (
    <>
      {selectedModal === 'ExitModal' && <ExitModal onCloseModal={onCloseModal} />}
      <Flex width="100%" background={'cornsilk'}>
        <HStack width="100%" m={2}>
          <Box
            display="flex"
            bg="#f9ba66"
            height="70px"
            width={{ base: '95%', sm: '97%', md: '98%' }}
            borderTopLeftRadius="50px"
            borderBottomLeftRadius="50px"
            borderTopRightRadius="md"
            borderBottomRightRadius="md"
            alignItems="center"
          >
            <Box
              bg="#fc9e2d"
              color="white"
              borderRadius="50%"
              width="70px"
              height="70px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="lg">{StoryLabel}</Text>
            </Box>
            <Box width={'80%'} display={'flex'} alignItems="center" justifyContent={'center'} m={1}>
              <Text fontWeight={'medium'}>{StoryTitle}</Text>
            </Box>
          </Box>
          <Box
            color="#ed7f35"
            fontSize="30px"
            display={'flex'}
            alignItems={'center'}
            justifyContent="center"
            m={1}
            onClick={() => onOpenModal('ExitModal')}
          >
            <BsXCircleFill />
          </Box>
        </HStack>
      </Flex>
    </>
  );
};

export default StoryHeader;
